<script setup lang="ts">
import { useRouter } from 'vue-router';
import { toast } from 'vue-sonner';

import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered, TDialogClose } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useResetPasswordMutation } from '@/queries/auth/use-reset-password-mutation.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

import ArrowLeftIcon from '~icons/iconoir/arrow-left';

const props = defineProps<{
  currentEmail: string;
}>();

defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const router = useRouter();

const onResetPasswordSuccess = () => {
  toast('A new code has been sent to your email');
};

const onResetPasswordError = (error: ApiError) => {
  toast.error(error.data.message);
};

const { mutate: resetPasswordMutation, isPending: isResetPasswordPending } =
  useResetPasswordMutation(onResetPasswordSuccess, onResetPasswordError);

const resetPassword = () => {
  resetPasswordMutation({ email: props.currentEmail });
};

const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = () => {
  changeModalState(ResetPasswordDialogState.sendEmail);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <button
      class="w-fit content-center text-primary-600 transition hover:text-primary-700"
      @click="goBack"
    >
      <ArrowLeftIcon />
    </button>
  </TDialogHeader>
  <TDialogClose @close="closeDialogAndRemoveQuery" />
  <TDialogContentBodyCentered class="gap-3">
    <h4 class="mb-7 font-bold">{{ $t('dialog.resetPassword.checkInbox') }}</h4>
    <div>
      <p class="break-all text-sm font-medium text-primary-700">
        {{ $t('dialog.resetPassword.emailSentTo') }} {{ currentEmail }}.
      </p>
    </div>
    <div class="mb-6 mt-6 flex items-start gap-3 rounded-lg border border-primary-300 p-4">
      <TIcon icon="infoEmpty" color="primary-800" />
      <div class="flex flex-1 flex-col gap-3">
        <p class="text-sm font-semibold text-primary-800">
          {{ $t('dialog.resetPassword.emailNotReceived') }}
        </p>
        <p class="text-sm font-medium text-primary-500">
          {{ $t('dialog.resetPassword.emailNotReceivedDescription') }}
        </p>
        <TButton
          class="w-fit"
          variant="outline"
          size="sm"
          :class="{ 'pointer-events-none': isResetPasswordPending }"
          @click="resetPassword"
        >
          <p v-if="!isResetPasswordPending">{{ $t('dialog.resetPassword.resendEmail') }}</p>
          <TWaveLoader v-else size="sm" />
        </TButton>
      </div>
    </div>
    <p class="mt-2 text-sm font-medium text-primary-600">
      {{ $t('dialog.resetPassword.troubleLogging') }}
      <a href="mailto:team@homaio.com" class="underline underline-offset-4">{{
        $t('dialog.resetPassword.contactSupport')
      }}</a>
    </p>
  </TDialogContentBodyCentered>
</template>
