<script setup lang="ts">
import { computed } from 'vue';

import KycVerticalStepper from '@/components/kyc/KycVerticalStepper.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { KycLegalForm } from '@/schemas/kyc/user-kyc-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { kycData } = useKycProcess(props.user);

const { closeDialog } = useKycDialog();

const isAllDone = computed(() => {
  return props.user.status === UserStatus.KYC_VERIFIED;
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <div class="h-full w-fit content-center text-primary-900">
      <LocaleSelector />
    </div>
    <template #modal-close />
  </TDialogHeader>
  <TDialogContentBodyCentered class="gap-3">
    <h4 class="mb-7 font-bold">{{ $t('dialog.kyc.thankYou.title') }}</h4>
    <p class="mb-2 text-sm font-medium text-primary-600">
      {{ isAllDone ? $t('dialog.kyc.thankYou.messageAllDone') : $t('dialog.kyc.thankYou.message') }}
    </p>
    <KycVerticalStepper
      :type="kycData.data.kyc.legalForm as KycLegalForm"
      :all-done="isAllDone"
      :personal-done="true"
      :company-done="true"
    />
    <TButton class="mt-7" @click="closeDialog">{{ $t('common.close') }}</TButton>
  </TDialogContentBodyCentered>
</template>
