<script setup lang="ts">
import { computed } from 'vue';

import { cn } from '@/lib/utils.ts';
import { VerticalStepperStep } from '@/types/vertical-stepper-step.ts';

const props = withDefaults(
  defineProps<{
    steps: VerticalStepperStep[];
    showLastConnector?: boolean;
  }>(),
  {
    steps: () => [],
    showLastConnector: false,
  },
);

const lastStepKey = computed(() => {
  const lastIndex = props.steps.length - 1;
  return props.steps[lastIndex].key;
});

const firstStepKey = computed(() => {
  return props.steps[0].key;
});

const showBottomConnector = (step: VerticalStepperStep) => {
  return step.key !== lastStepKey.value || props.showLastConnector;
};

const showTopConnector = (step: VerticalStepperStep) => {
  return step.key !== firstStepKey.value;
};
</script>

<template>
  <div class="block">
    <template v-for="step in steps" :key="step.key">
      <div class="flex items-center gap-4">
        <div>
          <div v-if="showTopConnector(step)" class="mb-[3px] flex w-2 justify-center">
            <div :class="cn('h-full min-h-4 w-[1.5px] bg-primary-300', step.upConnectorClass)" />
          </div>
          <div :class="cn('h-[10px] w-[10px] rounded-full bg-primary-300', step.dotClass)" />
          <div v-if="showBottomConnector(step)" class="mt-[3px] flex w-2 justify-center">
            <div :class="cn('h-full min-h-4 w-[1.5px] bg-primary-300', step.downConnectorClass)" />
          </div>
        </div>
        <div
          class="flex gap-1"
          :class="{
            'mt-[-20px]': firstStepKey === step.key,
            'mb-[-20px]': lastStepKey === step.key,
          }"
        >
          <p class="font-medium text-primary-600">
            {{ step.label }}
            <span v-if="step.suffix" class="font-semibold text-primary-800">{{ step.suffix }}</span>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
