<script setup lang="ts">
import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogClose, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { useDepositDialog } from '@/hooks/invest/use-deposit-dialog';
import { DepositDialogState } from '@/types/invest/deposit-dialog-state-enum';

const { changeModalState } = useDepositDialog();

const proceed = (): void => {
  changeModalState(DepositDialogState.Informations);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-end bg-white lg:bg-beige-100" />
  <TDialogClose />
  <TDialogContentBodyCentered class="gap-10">
    <h4 class="font-bold">{{ $t('dialog.deposit.intro.title') }}</h4>
    <div class="flex items-start gap-4 rounded-lg border border-danger-200 bg-danger-100 p-4">
      <TIcon icon="hexaWarningOutline" color="danger-600" size="md" />
      <div class="flex flex-1 flex-col gap-3 font-medium text-danger-600">
        <p class="text-sm font-semibold">{{ $t('dialog.deposit.intro.calloutTitle') }}</p>
        <div class="text-pretty break-normal text-sm opacity-60">
          <p v-html="$t('dialog.deposit.intro.calloutDescription1')" />
          <br />
          <p>
            {{ $t('dialog.deposit.intro.calloutDescription2') }}
          </p>
          <br />
          <p>
            {{ $t('dialog.deposit.intro.calloutDescription3') }}
          </p>
        </div>
      </div>
    </div>
    <TButton @click="proceed"> {{ $t('common.continue') }} </TButton>
  </TDialogContentBodyCentered>
</template>
