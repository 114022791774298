<script setup lang="ts">
import { computed, FunctionalComponent, SVGAttributes } from 'vue';

import EurCurrency from '@/assets/imgs/devises/eur.png';
import DepositDialog from '@/components/deposit/DepositDialog.vue';
import { TButton } from '@/components/ui/button';
import { TImage } from '@/components/ui/image';
import { TTooltip, TTooltipContent, TTooltipTrigger } from '@/components/ui/tooltip';
import WithdrawDialog from '@/components/withdraw/WithdrawDialog.vue';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { formatCurrency } from '@/lib/number-helpers.ts';
import { useLogoutMutation } from '@/queries/auth/use-logout-mutation.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';
import { TransferStatus } from '@/types/users/transfer-status-enum';
import { TransferType } from '@/types/users/transfer-type-enum';
import { UserStatus } from '@/types/users/user-status-enum.ts';

import Logout from '~icons/iconoir/log-out';
import Settings from '~icons/iconoir/settings';
import Strategy from '~icons/iconoir/strategy';

const { openDialog } = useKycDialog();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

type MenuItem = {
  pathName: string;
  icon: FunctionalComponent<SVGAttributes, any, any, any>;
  type: 'link' | 'button';
  disabled?: boolean;
  link?: string;
  onClick?: () => void;
};

const logoutMutation = useLogoutMutation();

const { data: user } = useUserMeQuery();

const isVerified = computed(() => {
  return user.value?.status === UserStatus.KYC_VERIFIED;
});

const walletEUR = computed(() => {
  return user.value?.wallets?.[0]?.value || 0;
});

const hasPendingWithdrawal = computed(() => {
  return user.value?.transfers?.some(
    (transfer) =>
      transfer.type === TransferType.WITHDRAW && transfer.status === TransferStatus.CREATED,
  );
});

const menuItems: MenuItem[] = [
  {
    pathName: 'settings',
    icon: Settings,
    disabled: false,
    type: 'link',
    link: '/settings',
  },
  {
    pathName: 'investmentStrategy',
    icon: Strategy,
    disabled: false,
    type: 'link',
    link: '/investment-strategy',
  },
  {
    pathName: 'logout',
    icon: Logout,
    type: 'button',
    onClick: () => logoutMutation.mutate(),
  },
];

const openKycDialog = (): void => {
  openDialog(KycDialogState.Intro);
  emits('close');
};
</script>

<template>
  <TTooltip :delay-duration="0">
    <TTooltipTrigger class="space-y-2" as-child>
      <div>
        <div class="flex flex-col gap-4 rounded-lg border border-primary-300 bg-primary-100 p-4">
          <div class="flex justify-between">
            <div class="flex items-center gap-2 text-data-h4 font-medium text-primary-800">
              <div
                class="flex h-[32px] w-[32px] items-center justify-center overflow-hidden rounded-full bg-[#1D267B]"
              >
                <TImage
                  :src="EurCurrency"
                  alt="Euro Currency"
                  title="Euro Currency"
                  width="18px"
                  height="18px"
                  class="mr-[3px]"
                />
              </div>
              EUR
            </div>
            <div class="flex flex-col gap-2">
              <div class="text-right font-medium text-primary-500">
                {{ $t('layout.navBar.userDropdown.balance') }}
              </div>
              <div class="text-data-h3 font-semibold text-primary-800">
                {{ formatCurrency(walletEUR, 'EUR') }}
              </div>
            </div>
          </div>
          <div class="flex justify-center gap-2">
            <template v-if="isVerified">
              <DepositDialog :disabled="hasPendingWithdrawal" />
            </template>
            <template v-else>
              <TButton variant="secondary" size="fluid-default" @click="openKycDialog">
                {{ $t('layout.navBar.userDropdown.deposit') }}
              </TButton>
            </template>
            <WithdrawDialog :disabled="hasPendingWithdrawal" />
          </div>
        </div>
        <div class="flex flex-col items-start justify-start gap-2">
          <template v-for="item in menuItems" :key="item.pathName">
            <TButton
              v-if="item.type === 'button'"
              :disabled="item.disabled"
              variant="link"
              class="gap-2 text-primary-800"
              @click="item.onClick"
            >
              <component :is="item.icon" class="h-5 w-5" />
              <div>{{ $t(`layout.navBar.userDropdown.items.${item.pathName}`) }}</div>
            </TButton>
            <TButton v-else :disabled="item.disabled" variant="link" as-child>
              <RouterLink :to="item.link!" class="gap-2 text-primary-800" @click="emits('close')">
                <component :is="item.icon" class="h-5 w-5" />
                <div>{{ $t(`layout.navBar.userDropdown.items.${item.pathName}`) }}</div>
              </RouterLink>
            </TButton>
          </template>
        </div>
      </div>
    </TTooltipTrigger>
    <TTooltipContent v-if="hasPendingWithdrawal" side="top" class="p-4 text-sm font-semibold">
      {{ $t('layout.navBar.userDropdown.pendingWithdrawal') }}
    </TTooltipContent>
  </TTooltip>
</template>
