<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { z } from 'zod';

import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogClose, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { FormControl, FormItem, FormField } from '@/components/ui/form';
import { TIcon } from '@/components/ui/icon';
import { TPasswordInput } from '@/components/ui/password-input';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { PASSWORD_REGEX, PASSWORD_RULES } from '@/constants/password-constants.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useChangePasswordMutation } from '@/queries/auth/use-change-password-mutation.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

import ArrowLeftIcon from '~icons/iconoir/arrow-left';

defineProps<{
  currentEmail: string;
}>();

defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const zodSchema = z.object({
  password: z
    .string()
    .regex(PASSWORD_REGEX.minLengthRegex)
    .regex(PASSWORD_REGEX.upperCaseRegex)
    .regex(PASSWORD_REGEX.lowerCaseRegex)
    .regex(PASSWORD_REGEX.specialCharRegex)
    .regex(PASSWORD_REGEX.numberRegex),
});

const passwordRules = computed(() => {
  return PASSWORD_RULES.map((rule) => {
    return {
      rule: rule.rule(values),
      message: rule.message,
    };
  });
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const router = useRouter();
const route = useRoute();

const onSubmit = handleSubmit(async (formValues) => {
  try {
    await resetPassword({
      password: formValues.password,
      token: route.query['reset-password-token'] as string,
    });
    await router.replace({ query: {} });
    closeDialog();
  } catch (error: unknown) {
    if (error instanceof ApiError) {
      errorMessage.value = error.data.message;
    }
  }
});

const { mutateAsync: resetPassword, isPending: isResetPasswordPending } =
  useChangePasswordMutation();

const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = async () => {
  await router.replace({ query: {} });
  changeModalState(ResetPasswordDialogState.sendEmail);
};

const passwordFocused = ref(false);
const showPasswordRules = computed(() => {
  if (passwordFocused.value) return true;
  if (!values.password) return false;

  return values.password.length > 0;
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <button
      class="mr-auto w-fit content-center text-primary-600 transition hover:text-primary-700"
      @click="goBack"
    >
      <ArrowLeftIcon />
    </button>
  </TDialogHeader>
  <TDialogClose @close="closeDialogAndRemoveQuery" />
  <TDialogContentBodyCentered>
    <h4 class="mb-7 font-bold">{{ $t('dialog.resetPassword.enterNewPassword') }}</h4>
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="password">
        <FormItem class="relative">
          <FormControl>
            <TPasswordInput
              v-bind="componentField"
              @focusin="passwordFocused = true"
              @focusout="passwordFocused = false"
            />
          </FormControl>
          <div
            v-if="showPasswordRules"
            class="absolute right-[-350px] top-[-50px] h-[240px] w-[315px] rounded-lg bg-white p-4 shadow-lg"
          >
            <p class="text-lg font-semibold text-primary-900">Your password must contain:</p>
            <div class="mt-4 flex flex-col gap-2">
              <div
                v-for="rule in passwordRules"
                :key="rule.message"
                class="flex items-center gap-2"
                :class="{ 'text-danger-500': !rule.rule, 'text-success-500': rule.rule }"
              >
                <TIcon size="sm" icon="checkCircleOutline" />
                <p>{{ rule.message }}</p>
              </div>
            </div>
          </div>
        </FormItem>
      </FormField>
      <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
      <TButton class="mt-7" type="submit" :disabled="isButtonDisabled">
        <TWaveLoader v-if="isResetPasswordPending" size="sm" class="bg-white" />
        <p v-else>{{ $t('dialog.resetPassword.action') }}</p>
      </TButton>
    </form>
    <p class="mt-2 text-sm font-medium text-primary-600">
      {{ $t('dialog.resetPassword.troubleLogging') }}
      <a href="mailto:team@homaio.com" class="underline underline-offset-4">{{
        $t('dialog.resetPassword.contactSupport')
      }}</a>
    </p>
  </TDialogContentBodyCentered>
</template>
