<script setup lang="ts">
import {
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '@/lib/utils';

const props = withDefaults(
  defineProps<
    DialogContentProps & {
      class?: HTMLAttributes['class'];
      position?: 'fullscreen' | 'center' | 'bottom' | 'left' | 'right';
    }
  >(),
  {
    position: 'fullscreen',
    class: '',
  },
);
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const positionClasses = computed(() => {
  const classes = {
    fullscreen:
      'inset-0 min-h-dvh rounded-none data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] lg:m-10 lg:h-[clamp(0px,100dvh-80px,768px)] lg:min-h-0 lg:w-[calc(100vw-80px)] lg:rounded-xl xl:bottom-[40px] xl:left-1/2 xl:right-auto xl:top-1/2 xl:max-w-[max(1152px,100vw-20vw)] xl:translate-x-[calc(-50%-40px)] xl:translate-y-[calc(-50%-40px)] xl:rounded-2xl 2xl:rounded-3xl lg:border lg:border-beige-300 overflow-y-auto',
    center:
      'inset-x-0 bottom-0 md:bottom-auto px-12 pb-12 pt-16 sm:w-fit data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:left-1/2 sm:right-auto sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 sm:rounded-2xl',
    bottom:
      'bottom-0 left-0 right-0 data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full sm:rounded-lg',
    left: 'left-0 top-0 h-full md:left-2 md:inset-y-2 md:h-[calc(100vh-16px)] sm:w-[538px] md:w-2/5 md:min-w-[517px] lg:left-4 lg:inset-y-4 lg:h-[calc(100vh-32px)] data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full rounded-none md:rounded-xl xl:rounded-2xl overflow-y-auto',
    right:
      'right-0 top-0 h-full md:right-2 md:inset-y-2 md:h-[calc(100vh-16px)] sm:w-[538px] md:w-2/5 md:min-w-[517px] lg:right-4 lg:inset-y-4 lg:h-[calc(100vh-32px)] data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full rounded-none md:rounded-xl xl:rounded-2xl overflow-y-auto',
  };

  return classes[props.position];
});
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'fixed z-50 flex flex-col shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
          props.class,
          positionClasses,
        )
      "
    >
      <div class="relative flex grow flex-col">
        <slot />
      </div>
    </DialogContent>
  </DialogPortal>
</template>
