<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue-sonner';
import { z } from 'zod';

import KycDialogBackBtn from '@/components/kyc/KycDialogBackBtn.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TAddressInput } from '@/components/ui/address-input';
import { TButton } from '@/components/ui/button';
import { TCalendarInput } from '@/components/ui/calendar-input';
import { TCountryFlag } from '@/components/ui/country-flag';
import { TDialogHeader, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import TInput from '@/components/ui/input/TInput.vue';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { alpha3ToAlpha2, Countries } from '@/constants/countries.ts';
import { countryPhoneCodes } from '@/constants/phone-code-constants.ts';
import { useAddressAutocomplete } from '@/hooks/kyc/use-address-autocomplete';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useCreateKycMutation } from '@/queries/kyc/use-create-kyc-mutation.ts';
import { useUpdateKycMutation } from '@/queries/kyc/use-update-kyc-mutation.ts';
import { CreateKycResponse } from '@/schemas/kyc/create-kyc-response-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { JsonResponse } from '@/types/api/json-response-type.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

// eslint-disable-next-line @typescript-eslint/unbound-method
const { tm: $tm, t: $t } = useI18n();

const zodSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  birthDate: z.string().date(),
  countryOfResidence: z.string(),
  preferredLanguage: z.string(),
  city: z.string(),
  postalCode: z.string(),
  country: z.string(),
  phoneCode: z.string(),
  phoneNumber: z.string(),
  address: z.string(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, setFieldValue, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const { kycData } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const goBack = (): void => {
  changeModalState(KycDialogState.LegalForm);
};

const isKycAlreadyCreated = computed(() => {
  return kycData.value.data.id !== '';
});

const preFillForm = (): void => {
  const birthDate = kycData.value.data.kyc.birthDate?.split('T')[0];

  setFieldValue('firstName', kycData.value.data.kyc.firstName);
  setFieldValue('lastName', kycData.value.data.kyc.lastName);
  setFieldValue('birthDate', birthDate);
  setFieldValue('countryOfResidence', kycData.value.data.kyc.countryOfResidence);
  setFieldValue('preferredLanguage', kycData.value.data.kyc.preferredLanguage);
  setFieldValue('phoneCode', kycData.value.data.kyc.phoneCode);
  setFieldValue('phoneNumber', kycData.value.data.kyc.phoneNumber);
  setFieldValue('address', kycData.value.data.kyc.address);
  setFieldValue('postalCode', kycData.value.data.kyc.postalCode);
  setFieldValue('city', kycData.value.data.kyc.city);
  setFieldValue('country', kycData.value.data.kyc.country);
};

const saveKyc = (data: CreateKycResponse): void => {
  kycData.value.data.id = data.id;
  kycData.value.data.kyc.firstName = data.firstName;
  kycData.value.data.kyc.lastName = data.lastName;
  kycData.value.data.kyc.birthDate = data.birthDate;
  kycData.value.data.kyc.countryOfResidence = data.countryOfResidence;
  kycData.value.data.kyc.preferredLanguage = data.preferredLanguage;
  kycData.value.data.kyc.phoneCode = data.phoneCode;
  kycData.value.data.kyc.phoneNumber = data.phoneNumber;
  kycData.value.data.kyc.address = data.address;
  kycData.value.data.kyc.postalCode = data.postalCode;
  kycData.value.data.kyc.city = data.city;
  kycData.value.data.kyc.country = data.country;
};

onMounted(() => {
  if (kycData.value.data.id !== '') {
    preFillForm();
  }
});

const setBirthDate = (value: string) => {
  setFieldValue('birthDate', value);
};

const {
  addressAutocompleteData,
  updateDebouncedAddress,
  addressSuggestions,
  isAddressAutocompletePending,
} = useAddressAutocomplete();

const acceptAutocomplete = (itemIndex: number) => {
  const item = addressAutocompleteData.value?.results[itemIndex];
  setFieldValue('address', item?.address);
  setFieldValue('city', item?.city);
  setFieldValue('postalCode', item?.postalCode);
  setFieldValue('country', item?.country);
};

const birthDate = computed(() => {
  if (kycData.value.data.id !== '') {
    return kycData.value.data.kyc.birthDate.split('T')[0];
  }

  return undefined;
});

const onSubmit = handleSubmit((formValues) => {
  const baseForm = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    legalForm: kycData.value.data.kyc.legalForm,
    address: formValues.address,
    postalCode: formValues.postalCode,
    city: formValues.city,
    country: formValues.country,
    countryOfResidence: formValues.countryOfResidence,
    preferredLanguage: formValues.preferredLanguage,
    birthDate: formValues.birthDate,
    phoneCode: formValues.phoneCode,
    phoneNumber: formValues.phoneNumber,
  };

  if (isKycAlreadyCreated.value) {
    updateKycMutation({ form: baseForm, kycId: kycData.value.data.id });
  } else {
    const formWithUserId = {
      ...baseForm,
      userId: props.user.id,
    };

    createKycMutation(formWithUserId);
  }
});

const countryList = computed(() => {
  return Countries;
});

const languageList = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const locales = $tm('locales') as Record<string, string>;
  return Object.keys(locales)
    .filter((key) => ['fr', 'en'].includes(key))
    .map((key) => ({ code: key, label: locales[key] }));
});

const phoneCodeList = computed(() => {
  const phoneCodes = countryPhoneCodes;
  return Object.keys(phoneCodes).map((key) => ({
    code: key,
    label: phoneCodes[key as keyof typeof phoneCodes],
  }));
});

const goNext = (): void => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    changeModalState(KycDialogState.BankInformation);
  } else {
    changeModalState(KycDialogState.IdentityDocuments);
  }
};

const onCreateKycSuccess = (response: CreateKycResponse) => {
  saveKyc(response);
  toast($t('dialog.kyc.personalInformation.kycSaved'));
  goNext();
};

const onCreateKycError = (error: ApiError) => {
  toast.error(error.data.message);
};

const onUpdateKycSuccess = (response: JsonResponse) => {
  toast($t('dialog.kyc.personalInformation.KycUpdated'));
  saveKyc(response as CreateKycResponse);
  goNext();
};

const onUpdateKycError = (error: ApiError) => {
  toast.error(error.data.message);
};

const { mutate: createKycMutation, isPending: isCreateKycPending } = useCreateKycMutation(
  onCreateKycSuccess,
  onCreateKycError,
);

const { mutate: updateKycMutation, isPending: isUpdateKycPending } = useUpdateKycMutation(
  onUpdateKycSuccess,
  onUpdateKycError,
);

watch(values, async () => {
  await updateDebouncedAddress(values.address);
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <div class="h-full w-fit content-center text-primary-900">
      <LocaleSelector />
    </div>
    <template #modal-close>
      <button class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </button>
    </template>
  </TDialogHeader>
  <TDialogContentBodyCentered class="gap-3">
    <div class="mb-7 flex flex-col gap-2">
      <KycDialogBackBtn class="w-fit" @click="goBack" />
      <h4 class="font-bold leading-9">{{ $t('dialog.kyc.personalInformation.title') }}</h4>
    </div>
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="firstName">
        <FormItem>
          <FormControl>
            <TInput
              :placeholder="$t('dialog.kyc.personalInformation.placeholder.firstName')"
              v-bind="componentField"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="lastName">
        <FormItem>
          <FormControl>
            <TInput
              :placeholder="$t('dialog.kyc.personalInformation.placeholder.lastName')"
              v-bind="componentField"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField name="birthDate">
        <FormItem>
          <FormControl>
            <TCalendarInput
              :initial-date="birthDate"
              :placeholder-label="$t('dialog.kyc.personalInformation.placeholder.birthDate')"
              @input="setBirthDate"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="countryOfResidence">
        <FormItem>
          <FormControl>
            <Select v-bind="componentField">
              <SelectTrigger>
                <div v-if="values.countryOfResidence" class="flex items-center gap-2">
                  <TCountryFlag
                    :alpha2-code="
                      alpha3ToAlpha2[values.countryOfResidence as keyof typeof alpha3ToAlpha2]
                    "
                  />
                  <p>{{ $t(`country.${values.countryOfResidence}`) }}</p>
                </div>
                <SelectValue
                  v-else
                  class="text-slate-500"
                  :placeholder="$t('dialog.kyc.personalInformation.placeholder.countryOfResidence')"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <RecycleScroller
                    v-slot="{ item }"
                    class="scroller"
                    :items="countryList"
                    :item-size="5"
                    key-field="code"
                  >
                    <SelectItem :value="item.code">
                      <div class="flex items-center gap-2">
                        <TCountryFlag
                          :alpha2-code="alpha3ToAlpha2[item.code as keyof typeof alpha3ToAlpha2]"
                        />
                        <p>{{ $t(`country.${item.code}`) }}</p>
                      </div>
                    </SelectItem>
                  </RecycleScroller>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="preferredLanguage">
        <FormItem>
          <FormControl>
            <Select v-bind="componentField">
              <SelectTrigger>
                <div v-if="values.preferredLanguage" class="flex items-center gap-2">
                  <p>{{ $t(`locales.${values.preferredLanguage}`) }}</p>
                </div>
                <SelectValue
                  v-else
                  class="text-slate-500"
                  :placeholder="$t('dialog.kyc.personalInformation.placeholder.preferredLanguage')"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem v-for="lang in languageList" :key="lang.code" :value="lang.code">
                    <div class="flex items-center gap-2">
                      <p>{{ $t(`locales.${lang.code}`) }}</p>
                    </div>
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <div class="grid grid-cols-2 gap-2">
        <FormField v-slot="{ componentField }" name="phoneCode">
          <FormItem>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <div v-if="values.phoneCode" class="flex items-center gap-2">
                    <p>
                      {{ values.phoneCode }} ({{
                        phoneCodeList.find((item) => item.code === values.phoneCode)?.label
                      }})
                    </p>
                  </div>
                  <SelectValue
                    v-else
                    class="text-slate-500"
                    :placeholder="$t('dialog.kyc.personalInformation.placeholder.phoneCode')"
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <RecycleScroller
                      v-slot="{ item }"
                      class="scroller"
                      :items="phoneCodeList"
                      :item-size="5"
                      key-field="code"
                    >
                      <SelectItem :value="item.code">
                        <div class="flex items-center gap-2">
                          <p>{{ item.code }} ({{ item.label }})</p>
                        </div>
                      </SelectItem>
                    </RecycleScroller>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="phoneNumber">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.personalInformation.placeholder.phoneNumber')"
                type="tel"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <FormField v-slot="{ componentField }" name="address">
        <FormItem>
          <FormControl>
            <TAddressInput
              :items="addressSuggestions"
              :placeholder="$t('dialog.kyc.personalInformation.placeholder.address')"
              v-bind="componentField"
              :is-loading="isAddressAutocompletePending"
              @update:auto-complete-value="acceptAutocomplete"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <div class="grid grid-cols-2 gap-2">
        <FormField v-slot="{ componentField }" name="postalCode">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.personalInformation.placeholder.postalCode')"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="city">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.personalInformation.placeholder.city')"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <FormField v-slot="{ componentField }" name="country">
        <FormItem>
          <FormControl>
            <Select v-bind="componentField">
              <SelectTrigger>
                <div v-if="values.country" class="flex items-center gap-2">
                  <TCountryFlag
                    :alpha2-code="alpha3ToAlpha2[values.country as keyof typeof alpha3ToAlpha2]"
                  />
                  <p>{{ $t(`country.${values.country}`) }}</p>
                </div>
                <SelectValue
                  v-else
                  class="text-slate-500"
                  :placeholder="$t('dialog.kyc.personalInformation.placeholder.country')"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <RecycleScroller
                    v-slot="{ item }"
                    class="scroller"
                    :items="countryList"
                    :item-size="5"
                    key-field="code"
                  >
                    <SelectItem :value="item.code">
                      <div class="flex items-center gap-2">
                        <TCountryFlag
                          :alpha2-code="alpha3ToAlpha2[item.code as keyof typeof alpha3ToAlpha2]"
                        />
                        <p>{{ $t(`country.${item.code}`) }}</p>
                      </div>
                    </SelectItem>
                  </RecycleScroller>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
      <TButton class="mt-7" type="submit" :disabled="isButtonDisabled">
        <TWaveLoader v-if="isCreateKycPending || isUpdateKycPending" size="sm" class="bg-white" />
        <p v-else>{{ $t('common.next') }}</p>
      </TButton>
    </form>
  </TDialogContentBodyCentered>
</template>
