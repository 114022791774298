<script setup lang="ts">
import ArrowLeftIcon from '~icons/iconoir/arrow-left';

const emit = defineEmits(['click']);
</script>

<template>
  <button class="flex items-center gap-2 leading-4 text-primary-700" @click="emit('click')">
    <ArrowLeftIcon class="h-3 w-3" />
    {{ $t('common.back') }}
  </button>
</template>
