<script setup lang="ts">
import { computed } from 'vue';

import Logo from '@/assets/imgs/logo.svg';
import KycDialog from '@/components/kyc/KycDialog.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TLink } from '@/components/ui/link';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { UserStatus } from '@/types/users/user-status-enum';

import LayoutMobileMenu from './LayoutMobileMenu.vue';
import LayoutNavBarUserDropdown from './LayoutNavBarUserDropdown.vue';

const { isAuthenticated } = useAuth();

const { openDialog } = useAuthDialog();

const { data: user } = useUserMeQuery();

const isUserVerified = computed(() => {
  return user.value?.status === UserStatus.KYC_VERIFIED;
});

const openLoginDialog = (): void => {
  openDialog(AuthDialogState.Login);
};

const openRegisterDialog = (): void => {
  openDialog(AuthDialogState.Register);
};
</script>

<template>
  <div class="fixed left-0 top-0 z-[20] w-full border-b border-primary-300 bg-white lg:relative">
    <nav
      class="container flex h-16 w-full max-w-screen-2xl items-center gap-4 px-4 lg:h-20 lg:px-16"
    >
      <RouterLink to="/" class="mr-10"
        ><component :is="Logo" class="h-[20px] w-[20px]"
      /></RouterLink>
      <div class="hidden flex-1 gap-10 lg:flex">
        <TLink class="[&.router-link-active]:text-primary-900" size="xl" :to="{ name: 'asset' }">{{
          $t('layout.navBar.asset')
        }}</TLink>
        <TLink
          class="[&.router-link-active]:text-primary-900"
          size="xl"
          :to="{ name: 'portfolio' }"
          >{{ $t('layout.navBar.portfolio') }}</TLink
        >
        <TLink
          class="[&.router-link-active]:text-primary-900"
          size="xl"
          :to="{ name: 'resources' }"
          >{{ $t('layout.navBar.resources') }}</TLink
        >
        <RouterLink class="hidden" to="/">{{ $t('layout.navBar.createAccount') }}</RouterLink>
      </div>
      <div class="hidden items-center gap-4 lg:flex">
        <LocaleSelector />
        <TButton v-if="!isAuthenticated" @click="openRegisterDialog">
          {{ $t('layout.navBar.createAccount') }}
        </TButton>
        <TButton v-if="!isAuthenticated" variant="light" @click="openLoginDialog">
          {{ $t('layout.navBar.login') }}
        </TButton>
        <KycDialog v-if="isAuthenticated && !isUserVerified" trigger-class="hidden" />
        <LayoutNavBarUserDropdown v-if="isAuthenticated" />
      </div>
      <div class="flex flex-1 justify-end lg:hidden">
        <LayoutMobileMenu />
      </div>
    </nav>
  </div>
</template>
