<script setup lang="ts">
import { type HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>
<template>
  <div class="flex flex-1 flex-col items-center overflow-y-auto px-6 lg:-mt-20 lg:h-full lg:w-full">
    <!-- XXX: complex css to center the content but keep a minimum "margin-top" of 80px and a "margin-bottom" of 80px -->
    <div class="lg:min-h-20 lg:flex-1" />
    <div :class="cn('flex w-[clamp(0px,448px,100vw-48px)] flex-col', props.class)">
      <slot />
    </div>
    <div class="lg:min-h-20 lg:flex-1">&nbsp;</div>
  </div>
</template>
