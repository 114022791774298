<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>

<template>
  <div
    :class="
      cn(
        'bottom-100 sticky mb-8 h-16 content-center rounded-none px-8 lg:mb-0 lg:h-20 lg:rounded-xl lg:px-10 xl:rounded-2xl 2xl:rounded-3xl',
        props.class,
      )
    "
  >
    <slot />
  </div>
</template>
