<script setup lang="ts">
import { computed, ref } from 'vue';

import Logo from '@/assets/imgs/logo.svg';
import KycDialog from '@/components/kyc/KycDialog.vue';
import LayoutNavBarUserDropdownContent from '@/components/layout/LayoutNavBarUserDropdownContent.vue';
import LocaleSelectorMobile from '@/components/LocaleSelectorMobile.vue';
import { TButton } from '@/components/ui/button';
import { TIcon } from '@/components/ui/icon';
import { TLink } from '@/components/ui/link';
import { TSheet, TSheetContent, TSheetHeader, TSheetTrigger } from '@/components/ui/sheet';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useLogoutMutation } from '@/queries/auth/use-logout-mutation.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

const { isAuthenticated } = useAuth();

const { openDialog } = useAuthDialog();

const { openDialog: openKycDialog } = useKycDialog();

const startKycProcess = (): void => {
  closeMenu();
  openKycDialog(KycDialogState.Intro);
};

const openLoginDialog = (): void => {
  closeMenu();
  openDialog(AuthDialogState.Login);
};

const openRegisterDialog = (): void => {
  closeMenu();
  openDialog(AuthDialogState.Register);
};

const logoutMutation = useLogoutMutation();

const logout = (): void => {
  closeMenu();
  logoutMutation.mutate();
};

const { data: user } = useUserMeQuery();

const isUserVerified = computed(() => {
  return user.value?.status === UserStatus.KYC_VERIFIED;
});

const closeMenu = (): void => {
  isOpen.value = false;
};

const isOpen = ref(false);
</script>

<template>
  <TSheet v-model:open="isOpen">
    <TSheetTrigger as-child>
      <TIcon class="h-6 w-6" icon="menu" color="primary-500" />
    </TSheetTrigger>
    <TSheetContent class="w-full">
      <TSheetHeader class="absolute left-0 right-0 top-0 flex h-16 justify-start px-6 py-4">
        <div class="w-fit">
          <component :is="Logo" class="h-[20px] w-[20px]" />
        </div>
      </TSheetHeader>
      <div class="no-scrollbar flex h-full w-full flex-col overflow-x-scroll pb-4 pt-12">
        <div
          class="mb-4 flex flex-col items-start justify-start gap-8 border-b border-primary-300 pb-6"
        >
          <TLink
            class="text-xl [&.router-link-active]:text-primary-900"
            size="xl"
            :to="{ name: 'asset' }"
            @click="closeMenu"
            >{{ $t('layout.navBar.asset') }}</TLink
          >
          <TLink
            class="text-xl [&.router-link-active]:text-primary-900"
            size="xl"
            :to="{ name: 'portfolio' }"
            @click="closeMenu"
            >{{ $t('layout.navBar.portfolio') }}</TLink
          >
          <TLink
            class="text-xl [&.router-link-active]:text-primary-900"
            size="xl"
            :to="{ name: 'resources' }"
            @click="closeMenu"
            >{{ $t('layout.navBar.resources') }}</TLink
          >
        </div>
        <div class="flex h-fit flex-grow flex-col items-end justify-end gap-4">
          <div v-if="!isAuthenticated && !isUserVerified" class="w-full py-4">
            <LocaleSelectorMobile />
          </div>
          <TButton v-if="!isAuthenticated" class="w-full" @click="openRegisterDialog">
            {{ $t('layout.navBar.createAccount') }}
          </TButton>
          <TButton v-if="!isAuthenticated" class="w-full" variant="light" @click="openLoginDialog">
            {{ $t('layout.navBar.login') }}
          </TButton>
          <KycDialog
            v-if="isAuthenticated && !isUserVerified"
            trigger-class="w-full h-11"
            :trigger-callback="startKycProcess"
          />
          <TButton
            v-if="isAuthenticated && !isUserVerified"
            class="w-full"
            variant="light"
            @click="logout"
          >
            {{ $t('layout.navBar.logout') }}
          </TButton>
          <div class="w-full border-b border-primary-300">
            <LayoutNavBarUserDropdownContent
              v-if="isAuthenticated && isUserVerified"
              @close="closeMenu"
            />
          </div>
          <div v-if="isAuthenticated && isUserVerified" class="w-full py-4">
            <LocaleSelectorMobile />
          </div>
        </div>
      </div>
    </TSheetContent>
  </TSheet>
</template>
