<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';

import BankAccountCard from '@/components/bank-accounts/BankAccountCard.vue';
import { TButton } from '@/components/ui/button';
import {
  TContentBlock,
  TContentBlockBody,
  TContentBlockHeader,
} from '@/components/ui/content-block';
import { TDialogHeader, TDialogClose } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import VerticalStepper from '@/components/VerticalStepper.vue';
import { useWithdrawCalculation } from '@/hooks/invest/use-withdraw-calculation';
import { useWithdrawDialog } from '@/hooks/invest/use-withdraw-dialog';
import { formatCurrency } from '@/lib/number-helpers.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { WithdrawDialogState } from '@/types/invest/withdraw-dialog-state-enum';

const breakpoints = useBreakpoints(breakpointsTailwind);
const lgBreakpoint = breakpoints.greaterOrEqual('lg');

const { closeDialog, changeModalState, steps } = useWithdrawDialog();

const { amount, allowedKeys, handleAmountFocus, handleAmountChange } = useWithdrawCalculation();

const { data: user } = useUserMeQuery();

const handleConfirm = () => {
  changeModalState(WithdrawDialogState.Confirmation);
};

const maxOutAmount = () => {
  amount.value = walletEUR.value;
};

const walletEUR = computed(() => {
  return user.value?.wallets?.[0]?.value || 0;
});

const hasEnoughFunds = computed(() => {
  if (!amount.value) return true;

  return amount.value <= walletEUR.value;
});

const hasNoFunds = computed(() => {
  return walletEUR.value === 0;
});

const amountClasses = computed(() => {
  return hasNoFunds.value || !hasEnoughFunds.value
    ? 'text-danger-500'
    : amount.value && amount.value > 0
      ? 'text-primary-800'
      : 'text-primary-400';
});
</script>

<template>
  <TDialogHeader v-if="!lgBreakpoint" class="flex flex-row justify-end bg-white lg:bg-beige-100" />
  <TDialogClose />
  <div
    class="mx-auto flex h-full max-w-[480px] flex-col gap-12 px-6 lg:pb-6 xl:mx-0 xl:max-w-full xl:flex-row xl:justify-between"
  >
    <div v-if="lgBreakpoint" />
    <div class="flex shrink-0 grow flex-col xl:max-w-[530px]">
      <div class="flex flex-col xl:mt-20">
        <h4 class="mb-10 font-bold">{{ $t('common.transactionType.withdraw') }}</h4>
        <div>
          <div class="flex w-full flex-col gap-2">
            <div
              class="flex w-full flex-col gap-6 rounded-lg border border-primary-300 bg-white p-4"
            >
              <div class="flex justify-between">
                <div>
                  <p class="mb-4 font-medium text-primary-600">
                    {{ $t('layout.navBar.userDropdown.withdraw') }}
                  </p>
                  <div
                    class="align-center mb-6 inline-flex items-center justify-start gap-2 text-data-h1 font-semibold"
                    :class="amountClasses"
                  >
                    <span
                      role="textbox"
                      contenteditable
                      inputmode="decimal"
                      class="min-w-[20px] max-w-[250px] appearance-none border-none bg-transparent focus:outline-none"
                      @input="handleAmountChange"
                      @keypress="allowedKeys"
                      @focus="handleAmountFocus"
                    >
                      {{ amount }}
                    </span>
                    <span>€</span>
                  </div>
                </div>
                <TButton
                  variant="ghost"
                  class="text-secondary"
                  type="button"
                  @click="maxOutAmount"
                  >{{ $t('component.withdraw.max') }}</TButton
                >
              </div>

              <div
                class="flex w-full items-center justify-between gap-6 rounded-xl bg-beige-100 p-4"
              >
                <div class="flex grow items-center justify-between">
                  <div class="flex gap-4">
                    <div
                      class="flex h-[52px] w-[52px] items-center justify-center rounded-xl bg-beige-400"
                    >
                      <TIcon icon="drawer" color="primary-500" size="md" />
                    </div>
                    <div class="flex flex-col items-start justify-center font-medium">
                      <span class="text-left text-lg text-primary-800">
                        {{ $t('component.withdraw.from') }}</span
                      >
                      <span class="text-primary-600"> {{ $t('component.buy.eurWallet') }}</span>
                    </div>
                  </div>

                  <div v-if="!hasNoFunds" class="text-sm font-semibold text-primary-700">
                    <p class="text-lg font-medium text-primary-800">
                      {{ formatCurrency(walletEUR, 'EUR') }}
                    </p>
                    <p class="text-right text-primary-600">
                      {{ $t('component.withdraw.available') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative">
              <div
                class="absolute left-1/2 top-0 flex h-10 w-10 -translate-x-1/2 -translate-y-[60%] items-center justify-center rounded-md bg-primary-700"
              >
                <TIcon icon="arrowDown" color="white" size="md" />
              </div>
            </div>
            <BankAccountCard can-edit @edit="closeDialog">
              <template #title>
                <span class="text-primary-600">{{ $t('component.withdraw.to') }}</span>
              </template>
            </BankAccountCard>
          </div>
        </div>
      </div>
    </div>
    <TContentBlock class="flex max-w-[504px] flex-col rounded-xl shadow-md xl:mt-6 xl:rounded-2xl">
      <TContentBlockHeader class="mb-0" has-separator
        ><span class="font-semibold text-primary-800"
          >{{ $t('component.buy.transactionDetails') }}
        </span></TContentBlockHeader
      >
      <TContentBlockBody
        class="relative !mb-0 flex flex-1 flex-col gap-10 overflow-y-auto px-8 pt-6 xl:basis-0"
      >
        <div>
          <p class="mb-4 font-medium text-primary-800">{{ $t('component.withdraw.receive') }}</p>

          <div class="flex flex-col items-start gap-2">
            <span class="text-data-h1 font-semibold text-primary-800">{{ amount ?? 0 }}€</span>
          </div>
        </div>
        <div class="flex grow flex-col">
          <p class="mb-6 font-medium text-primary-800">
            {{ $t('component.withdraw.processDetails') }}
          </p>
          <VerticalStepper :steps="steps" class="mb-8" />
        </div>
        <TButton
          class="sticky bottom-4 -mx-4 w-[calc(107%)]"
          size="fluid-default"
          :disabled="!hasEnoughFunds || (amount ?? 0) <= 0"
          @click="handleConfirm"
        >
          <p>{{ $t('component.withdraw.continue') }}</p>
        </TButton>
      </TContentBlockBody>
    </TContentBlock>
  </div>
</template>
