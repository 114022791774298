<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue-sonner';

import KycDialogBackBtn from '@/components/kyc/KycDialogBackBtn.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { TDragDropUploader } from '@/components/ui/drag-drop-uploader';
import { TIcon } from '@/components/ui/icon';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { useApi } from '@/hooks/use-api.ts';
import ApiError from '@/models/api/api-error.ts';
import { useFinishKycMutation } from '@/queries/kyc/use-finish-kyc-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import IApiResponse from '@/types/api/api-response-interface';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { t: $t } = useI18n();

const api = useApi();

const fileLoading = ref({ back: false, front: false });
const fileUploaded = ref({ back: false, front: false });
const frontUploadUrl = ref('');
const backUploadUrl = ref('');
const verifyLoading = ref(false);

const isButtonDisabled = computed(() => !frontUploadUrl.value && !backUploadUrl.value);

const { kycData } = useKycProcess(props.user);
const queryClient = useQueryClient();

const { closeDialog, changeModalState } = useKycDialog();

const goBack = (): void => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    changeModalState(KycDialogState.BankInformation);
  } else {
    changeModalState(KycDialogState.PersonalInformation);
  }
};

const goNext = (): void => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    changeModalState(KycDialogState.ThankYou);
  } else {
    changeModalState(KycDialogState.CompanyInformation);
  }
};

const frontDocument = ref<File | null>(null);
const backDocument = ref<File | null>(null);
const showBackDocument = ref(false);

const removeFrontDocument = () => {
  frontDocument.value = null;
  fileUploaded.value.front = false;
  frontUploadUrl.value = '';
};

const removeBackDocument = () => {
  backDocument.value = null;
  fileUploaded.value.back = false;
  backUploadUrl.value = '';
};

const uploadFileToAws = async (url: string, file: File, type: 'front' | 'back') => {
  try {
    await api.uploadFile(url, file);

    fileUploaded.value[type] = true;
  } catch (error) {
    console.error(error);
    if (type === 'front') {
      frontDocument.value = null;
    } else {
      backDocument.value = null;
    }

    toast.error($t('dialog.kyc.identityDocuments.uploadError'));
  }
};

const uploadDocuments = async () => {
  try {
    verifyLoading.value = true;

    if (frontUploadUrl.value !== '') {
      await uploadFileToAws(frontUploadUrl.value, frontDocument.value as File, 'front');
    }

    if (backUploadUrl.value !== '') {
      await uploadFileToAws(backUploadUrl.value, backDocument.value as File, 'back');
    }

    await verifyDocuments();
  } catch (error) {
    console.error(error);

    toast.error($t('dialog.kyc.identityDocuments.uploadError'));
  }

  verifyLoading.value = false;
};

const verifyDocuments = async () => {
  verifyLoading.value = true;
  const verifyPromises: Promise<IApiResponse<void>>[] = [];

  if (fileUploaded.value.front) {
    verifyPromises.push(
      api.get<void>(
        `/kyc/${kycData.value.data.id}/document/verify?filename=${frontDocument.value?.name}`,
      ),
    );
  }

  if (fileUploaded.value.back) {
    verifyPromises.push(
      api.get<void>(
        `/kyc/${kycData.value.data.id}/document/verify?filename=${backDocument.value?.name}`,
      ),
    );
  }

  await Promise.all(verifyPromises);

  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    finishKyc();
  } else {
    await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
    verifyLoading.value = false;
    goNext();
  }
};

const onKycDoneSuccess = (): void => {
  verifyLoading.value = false;
  goNext();
};

const onKycDoneError = (_: ApiError): void => {
  verifyLoading.value = false;
  toast.error($t('common.genericError'));
};

watch(frontDocument, async (newDocument) => {
  if (newDocument) {
    fileLoading.value.front = true;

    const response = await api.get<string>(`/kyc/${kycData.value.data.id}/document/upload`, {
      filename: newDocument.name,
    });

    fileLoading.value.front = false;
    frontUploadUrl.value = response.data;
  }
});

watch(backDocument, async (newDocument) => {
  if (newDocument) {
    fileLoading.value.back = true;

    const response = await api.get<string>(`/kyc/${kycData.value.data.id}/document/upload`, {
      filename: newDocument.name,
    });

    fileLoading.value.back = false;
    backUploadUrl.value = response.data;
  }
});

const { mutate: finishKycMutation } = useFinishKycMutation(onKycDoneSuccess, onKycDoneError);

const finishKyc = (): void => {
  finishKycMutation(kycData.value.data.id);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <div class="h-full w-fit content-center text-primary-900">
      <LocaleSelector />
    </div>
    <template #modal-close>
      <button class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </button>
    </template>
  </TDialogHeader>
  <TDialogContentBodyCentered>
    <div class="mb-7 flex flex-col gap-2">
      <KycDialogBackBtn class="w-fit" @click="goBack" />
      <h4 class="font-bold leading-9">{{ $t('dialog.kyc.identityDocuments.title') }}</h4>
    </div>
    <div class="flex flex-col gap-4">
      <p class="mb-6 text-sm font-medium text-primary-700">
        {{ $t('dialog.kyc.identityDocuments.description') }}
      </p>
      <TDragDropUploader
        v-model="frontDocument"
        file-accept="image/png, image/jpeg, image/jpg, application/pdf"
      >
        <template #message>
          <div
            class="flex h-40 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-center text-sm font-medium text-primary-500"
          >
            <TIcon icon="upload" color="primary-700" size="md" />
            <p class="mt-4 font-semibold text-primary-700">
              {{ $t('dialog.kyc.identityDocuments.uploadTitle') }}
            </p>
            <p class="text-xs font-semibold text-primary-700">
              {{ $t('dialog.kyc.identityDocuments.uploadDescription') }}
            </p>
            <p class="mt-4 text-xs font-medium text-primary-500">
              {{ $t('dialog.kyc.identityDocuments.uploadNotice') }}
            </p>
          </div>
        </template>
        <template #preview="{ currentFile }">
          <div
            class="flex h-40 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-center text-sm font-medium text-primary-500"
          >
            <div
              v-if="!fileLoading.front"
              class="flex h-full w-full flex-col items-center justify-center text-center"
            >
              <TIcon icon="emptyPage" color="primary-700" size="md" />
              <p class="mt-4 line-clamp-2 font-semibold text-primary-700">
                {{ (currentFile as File)?.name }}
              </p>
              <p class="mt-4 text-xs font-medium text-primary-500" @click="removeFrontDocument">
                {{ $t('dialog.kyc.identityDocuments.removeFile') }}
              </p>
            </div>
            <div v-else class="flex h-full w-full flex-col items-center justify-center">
              <TWaveLoader size="sm" />
            </div>
          </div>
        </template>
      </TDragDropUploader>
      <TDragDropUploader
        v-if="showBackDocument"
        v-model="backDocument"
        file-accept="image/png, image/jpeg, image/jpg, application/pdf"
      >
        <template #message>
          <div
            class="flex h-40 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-center text-sm font-medium text-primary-500"
          >
            <TIcon icon="upload" color="primary-700" size="md" />
            <p class="mt-4 font-semibold text-primary-700">
              {{ $t('dialog.kyc.identityDocuments.uploadTitle') }}
            </p>
            <p class="text-xs font-semibold text-primary-700">
              {{ $t('dialog.kyc.identityDocuments.uploadDescription') }}
            </p>
            <p class="mt-4 text-xs font-medium text-primary-500">
              {{ $t('dialog.kyc.identityDocuments.uploadNotice') }}
            </p>
          </div>
        </template>
        <template #preview="{ currentFile }">
          <div
            class="h-40 cursor-pointer rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-sm font-medium text-primary-500"
          >
            <div
              v-if="!fileLoading.back"
              class="flex h-full w-full flex-col items-center justify-center text-center"
            >
              <TIcon icon="emptyPage" color="primary-700" size="md" />
              <p class="mt-4 line-clamp-2 font-semibold text-primary-700">
                {{ (currentFile as File)?.name }}
              </p>
              <p class="mt-4 text-xs font-medium text-primary-500" @click="removeBackDocument">
                {{ $t('dialog.kyc.identityDocuments.removeFile') }}
              </p>
            </div>
            <div v-else class="flex h-full w-full flex-col items-center justify-center">
              <TWaveLoader size="sm" />
            </div>
          </div>
        </template>
      </TDragDropUploader>
      <p
        class="mb-6 mt-2 cursor-pointer text-sm font-medium text-primary-500"
        :class="showBackDocument ? 'hidden' : ''"
        @click="showBackDocument = true"
      >
        {{ $t('dialog.kyc.identityDocuments.addDocument') }}
      </p>
      <TButton class="mt-6" :disabled="isButtonDisabled" @click="uploadDocuments">
        <TWaveLoader v-if="verifyLoading" size="sm" class="bg-white" />
        <p v-else>{{ $t('common.next') }}</p>
      </TButton>
    </div>
  </TDialogContentBodyCentered>
</template>
