import { z } from 'zod';

import { HomaioAsset } from '@/types/homaio-asset-enum';
import { AlertWay } from '@/types/settings/alert-enum';

export const PriceAlertSchema = z.object({
  id: z.string(),
  price: z.number(),
  asset: z.nativeEnum(HomaioAsset),
  way: z.nativeEnum(AlertWay),
  triggered: z.boolean(),
  enabled: z.boolean(),
});

export type PriceAlert = z.infer<typeof PriceAlertSchema>;
